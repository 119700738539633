import { Form, FormikHelpers, FormikProps } from "formik";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import * as Yup from "yup";

import Button from "../../../atoms/button/Button";
import PageStepAnimation from "../../../atoms/page-step-animation/PageStepAnimation";
import TextFormControl from "../../../atoms/text-form-control/TextFormControl";
import { validateEmail } from "../../../core/utils/validators";
import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import PageStepForm from "../../../organisms/page-step-form/PageStepForm";
import { Result } from "../../../organisms/result/domain/result";
import { useResult, useUpdateResult } from "../../../organisms/result/use-cases/result-use-cases";
import { PageId } from "../../../settings/pages";
import { Events } from "../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../utils/hooks/useTrackPageViewed";
import {
  shouldAllowFakeChipNumber,
  shouldShowDescriptionInAccessPage,
} from "../../../utils/locale-configuration-utils";
import * as styles from "../../Access.module.scss";

const accessSVG: string = require("../../../images/pages/results/access/envelope.svg").default;

interface AccessFormValues {
  pet_parent_email: string;
  confirmation_email: string;
}

const Page = (): JSX.Element => {
  const { t } = useTranslation();
  const result = useResult() as Result;
  const updateResult = useUpdateResult();
  const hasFakeChipNumber: boolean = shouldAllowFakeChipNumber(result?.country);
  const nextUrl = hasFakeChipNumber
    ? `/results/${result?.uuid}/pet_family/`
    : `/results/${result?.uuid}/chip/`;
  const hasDescription: boolean = shouldShowDescriptionInAccessPage(result?.country);

  useTrackPageViewed(Events.ACCESS_VIEWED_BROWSER);

  const emailValidationSchema = Yup.mixed()
    .required(t("common.validation.required"))
    .test("validate-email", t("common.validation.valid_email"), (value: string): boolean => {
      if (!value) {
        return false;
      }

      return validateEmail(value);
    });

  const accessFormData = {
    initialValues: { pet_parent_email: result?.email || "", confirmation_email: "" },
    validationSchema: Yup.object({
      pet_parent_email: emailValidationSchema,
      confirmation_email: emailValidationSchema.equals(
        [Yup.ref("pet_parent_email"), null],
        t("common.validation.not_equal")
      ),
    }),
    handleSubmit: (
      values: AccessFormValues,
      { setSubmitting }: FormikHelpers<AccessFormValues>
    ) => {
      if (!updateResult) {
        return;
      }

      setSubmitting(true);
      updateResult({
        pet_parent_email: values.pet_parent_email,
      })
        .then(() => navigate(nextUrl))
        .catch((err: Error) => {
          handleUpdateResultErrors(err, "access");
          alert(t("landing.error_message"));
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<AccessFormValues>) => {
      const { isSubmitting, isValid, dirty } = props;

      return (
        <Form className={styles.accessForm}>
          <TextFormControl name="pet_parent_email" label={t("pg_access.email")} type={"email"} />
          <TextFormControl
            name="confirmation_email"
            label={t("pg_access.confirmation_email")}
            type={"email"}
          />
          <Button
            type="submit"
            disabled={isSubmitting || !(isValid && dirty)}
            isLoading={isSubmitting}
          >
            {t("pg_access.cta")}
          </Button>
          {hasDescription && (
            <p className={styles.description}>
              <small>{parse(t("pg_access.description"))}</small>
            </p>
          )}
        </Form>
      );
    },
  };

  return (
    <>
      {result && (
        <PageStepAnimation>
          <PageStepForm
            title={t("pg_access.title")}
            {...(!hasDescription && { subtitle: parse(t("pg_access.subtitle")) })}
            image={accessSVG}
            formData={accessFormData}
          />
        </PageStepAnimation>
      )}
    </>
  );
};

export default withFunnelWrapper(Page, "pg-access", PageId.access);

export const query = graphql`
  query AccessRequestQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
