import { Link } from "gatsby";
import { AnchorHTMLAttributes, MutableRefObject, ReactNode, useEffect, useRef } from "react";

import { Events, trackLink } from "../../utils/analytics";

interface TrackableLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  children?: ReactNode;
  openInNewWindow?: boolean;
  eventName?: string;
  extraEventProperties?: Record<string, unknown> | undefined;
  isExternalLink?: boolean;
  adoptionClassName?: string;
  eventSender?: string;
}

const TrackableLink = ({
  to,
  children,
  openInNewWindow,
  extraEventProperties,
  eventSender,
  eventName = Events.CLICKED_EXTERNAL_LINK,
  isExternalLink = true,
  adoptionClassName,
  ...props
}: TrackableLinkProps): JSX.Element => {
  const linkElement: MutableRefObject<null> = useRef(null);

  useEffect((): void => {
    linkElement.current &&
      trackLink(linkElement.current, eventName, {
        destination: to,
        ...{ eventSender },
        ...extraEventProperties,
      });
    // We leave the dependency array empty because extraEventProperties is an object and causes re-rendering.
    // Even if the object has the same properties as another, the result of the comparison to see if they are the same will be false.
    // We know that the props are passing to TrackableLink never change after render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isExternalLink ? (
    <a
      ref={linkElement}
      href={to}
      className={adoptionClassName}
      {...props}
      {...(openInNewWindow && { target: "_blank", rel: "noreferrer" })}
    >
      {children}
    </a>
  ) : (
    <Link ref={linkElement} to={to} {...props} className={adoptionClassName}>
      {children}
    </Link>
  );
};

export default TrackableLink;
