import { fetchApi } from "../../../utils/request";

export const customCheckoutSession = async (resultUuid: string): Promise<unknown> => {
  return await fetchApi(
    `${process.env.GATSBY_API_BASE}/results/${resultUuid}/custom_checkout_session/`,
    {
      method: "POST",
    }
  );
};
