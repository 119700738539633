import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { countries, CountryCode } from "../../settings/countries";
import TrackableLink from "../trackable-link/TrackableLink";
import * as styles from "./ChatLink.module.scss";

interface ChatLinkProps {
  countryCodeIso: CountryCode;
}

const ChatLink = ({ countryCodeIso }: ChatLinkProps): JSX.Element => {
  const { t } = useTranslation();

  const destination =
    countries[countryCodeIso].contactMethods.filter(
      (contactMethod) => contactMethod.type === "whatsapp"
    )[0]?.destination || "";

  return (
    <TrackableLink
      className={cn("chat-link", styles.chatLink)}
      to={destination}
      aria-label={t(`common.whatsapp_contact.aria_label`)}
      title={t(`common.whatsapp_contact.title`)}
      eventSender={"Chat Link"}
      openInNewWindow
    >
      <svg className={cn("icon", `icon--whatsapp`, styles.icon)} role="img">
        <use href={`#icon-whatsapp`}></use>
      </svg>
    </TrackableLink>
  );
};

export default ChatLink;
