import { Component } from "react";

import FunnelLayout from "../funnel-layout/FunnelLayout";

const withFunnelWrapper = (Page, pageClass, pageId, followResultRedirect = true) => {
  class WithFunnelWrapper extends Component {
    render() {
      const { params } = this.props;

      return (
        <FunnelLayout
          params={params}
          pageClass={`tp-funnel ${pageClass}`}
          pageId={pageId}
          followResultRedirect={followResultRedirect}
        >
          <Page {...this.state} {...this.props} />
        </FunnelLayout>
      );
    }
  }

  return WithFunnelWrapper;
};

export default withFunnelWrapper;
