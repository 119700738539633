import { useEffect, useState } from "react";

import { DealEventProperties } from "../../organisms/deal-event-properties/domain/deal-event-properties";
import { useDealEventProperties } from "../../organisms/deal-event-properties/use-cases/deal-event-properties-use-cases";
import { useDataLayerProperties } from "../../organisms/result/use-cases/result-use-cases";
import { track } from "../analytics";
import { getWindow } from "../browser-features";

const useTrackPageViewed = (pageViewedEvent: string, extraProperties = {}): void => {
  const dealEventProperties: DealEventProperties | null = useDealEventProperties();
  const dataLayerProperties: Record<string, unknown> | null = useDataLayerProperties();
  const dataLayer = getWindow()?.dataLayer || [];
  const [isDealEventPropertiesReady, setDealEventPropertiesReady] = useState<boolean>(false);
  const [isDataLayerPropertiesReady, setDataLayerPropertiesReady] = useState<boolean>(false);

  useEffect(() => {
    setDealEventPropertiesReady(!!dealEventProperties);
    setDataLayerPropertiesReady(!!dataLayerProperties);
  }, [dealEventProperties, dataLayerProperties]);

  useEffect(() => {
    if (isDealEventPropertiesReady) {
      dealEventProperties && track(pageViewedEvent, { ...dealEventProperties, ...extraProperties });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDealEventPropertiesReady]);

  useEffect(() => {
    if (dataLayer && isDataLayerPropertiesReady) {
      dataLayer.push({ event: pageViewedEvent, ...dataLayerProperties });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLayerPropertiesReady]);
};

export default useTrackPageViewed;
